import nl2br from 'nl2br'

export default {

	methods: {

		$nl2br: function(text) {

			return nl2br(text)

		}

	}

}