<template>

<div class="items">

	<div class="items-thumbs" v-if="!isViewing">

		<template v-for="item in items">

			<router-link v-if="linkItem(item)" :to="linkItem(item)" class="items-thumbs-item" :class="{'is-excluded': item.exclude}" :key="item.id" :style="{backgroundImage: 'url(' + $store.getters['base'] + item.image + ')'}">

				<span>{{ item.title }}</span>

				<div class="item-thumbs-item-exclude" v-if="item.exclude" />

			</router-link>

		</template>

	</div>

	<div class="item-view" v-if="isViewing">

		<div class="item-view-image" role="img" :style="{backgroundImage: 'url(' + $store.getters['base'] + item.image + ')'}">

			<router-link :to="linkGroup" class="item-view-image-close"></router-link>

		</div>

		<h4>{{ item.title }}</h4>
		<h5>{{ item.subtitle }}</h5>

	</div>

</div>

</template>

<script>

export default {

	props: ['group'],

	computed: {

		isViewing: function() {

			return this.$store.getters['item']

		},

		item: function() {

			return (this.isViewing) ? this.$_.findWhere(this.$store.getters['items'], {
				id: this.isViewing
			}) : false

		},

		items: function() {

			return this.$_.where(this.$store.getters['items'], {
				group: this.group,
				visible: 1
			})

		},

		linkGroup: function() {

			return {
				name: 'Group'
			}

		}

	},

	methods: {

		linkItem: function(item) {

			if (item.exclude) {

				var alternate = this.$_.findWhere(this.$store.getters['groups'], {
					id: item.alternate
				})

				if (!alternate) return false

				var category = this.$_.findWhere(this.$store.getters['categories'], {
					id: alternate.category
				})

				if (!category) return false

				return {
					name: 'Group',
					params: {
						key: this.$route.params.key || null,
						category: category.slug,
						group: alternate.slug
					}
				}

			} else {

				return {
					name: 'Item',
					params: {
						key: this.$route.params.key || null,
						item: item.slug
					}
				}

			}

		}

	}

}

</script>

<style scoped>

.items-thumbs {
	display: flex;
	flex-wrap: wrap;
}

.items-thumbs-item {
	width: calc(33.3% - 7px);
	height: 100px;
	background-color: #F9F9F9;
	border-radius: 12px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	margin-bottom: 10px;
}

.items-thumbs-item:nth-child(2),
.items-thumbs-item:nth-child(5),
.items-thumbs-item:nth-child(8),
.items-thumbs-item:nth-child(11),
.items-thumbs-item:nth-child(14),
.items-thumbs-item:nth-child(17),
.items-thumbs-item:nth-child(20),
.items-thumbs-item:nth-child(23),
.items-thumbs-item:nth-child(26) {
	margin: 0px 10px;
}

.items-thumbs-item.is-excluded {
	background-color: #FFECED;
}

.items-thumbs-item span {
	opacity: 0;
	pointer-events: none;
	position:
}

.item-thumbs-item-exclude {
	background-image: url(~@/assets/content.cross.png);
	width: 20px;
	height: 20px;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: absolute;
	left: 4px;
	top: 4px;
}

.item-view {
	padding-bottom: 10px;
}

.item-view-image {
	background-color: #F9F9F9;
	border-radius: 12px;
	width: 100%;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	height: 270px;
	margin-bottom: 20px;
}

.item-view h4 {
	color: #707070;
	text-align: center;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 1.8px;
	margin-bottom: 5px;
}

.item-view h5 {
	color: #707070;
	text-align: center;
	font-size: 16px;
	letter-spacing: 1.4px;
}

.item-view-image-close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 20px;
	font-size: 14px;
	text-align: center;
	line-height: 16px;
	height: 20px;
	border-radius: 50%;
	background-image: url(~@/assets/close.png);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

</style>
