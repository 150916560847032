<template>

<div class="content">

	<h5 class="content-title" v-if="isTitle">{{ text.content }}</h5>

	<img class="content-image" v-if="isImage" :src="$store.getters['base'] + text.image" :alt="text.title" />

	<div class="content-text" v-if="isText" v-html="text.content"></div>

	<router-link :to="linkGroup" class="content-bullet is-linked" v-if="isCross && group" :class="{[clsType]: true}">

		<div class="content-bullet-icon"></div>
		<div class="content-bullet-text" v-html="textCross"></div>

	</router-link>

	<div class="content-bullet" v-if="isCross && !group" :class="{[clsType]: true}">

		<div class="content-bullet-icon"></div>
		<div class="content-bullet-text" v-html="textCross"></div>

	</div>

	<div class="content-bullet" v-if="isBullet && !isCross && !isTick" :class="{[clsType]: true}">

		<div class="content-bullet-icon"></div>
		<div class="content-bullet-text" v-if="text.type !== $constants.text.type.tick && text.type !== $constants.text.type.link" v-html="$nl2br(text.content)"></div>
		<h3 class="content-bullet-text" v-if="text.type === $constants.text.type.tick">{{ text.content }}</h3>
		<a :href="text.url" target="_blank" class="content-bullet-text" v-if="text.type === $constants.text.type.link">{{ text.content }}</a>

	</div>

	<app-content-tick v-if="this.text.type === this.$constants.text.type.tick" :item="text" />
	<app-content-group v-if="this.text.type === this.$constants.text.type.group" :item="text" />
	<app-content-connectedgroups v-if="this.text.type === this.$constants.text.type.connectedgroups" :item="text" />

	<router-link :to="linkLocation" class="content-location" v-if="isLocation && location">

		<div class="content-location-logo" :style="{backgroundImage: 'url(' + $store.getters['base'] + location.logo + ')'}"></div>
		<div class="content-location-text">
			
			<h3>{{ location.title }}</h3>
			<h5>{{ locationServices }}</h5>
			
		</div>

	</router-link>

	<div class="checklist" v-if="text.type === $constants.text.type.checklist">

		<div class="tick-item content-bullet is-checklist" v-for="(check, index) in checks" :key="index">

			<div class="content-bullet-icon"></div>
			<div class="content-bullet-text" v-html="check"></div>

		</div>

	</div>

	<div v-if="isEmbed" class="content-embed" v-html="text.content" />

</div>

</template>

<script>

import nl2br from 'nl2br'

export default {

	props: ['id'],

	created: function() {

		this.$nextTick(function() {

			window.twttr.widgets.load()
			window.instgrm.Embeds.process()

		})

	},

	computed: {

		checks: function() {

			return this.text.content.split(/\r?\n/)

		},

		location: function() {

			return (this.text.location) ? this.$_.findWhere(this.$store.getters['locations'], {
				id: this.text.location
			}) : {}

		},

		group: function() {

			return (this.text.group) ? this.$_.findWhere(this.$store.getters['groups'], {
				id: parseInt(this.text.group)
			}) : false

		},

		groupCategory: function() {

			return (this.text.group) ? this.$_.findWhere(this.$store.getters['categories'], {
				id: this.group.category
			}) : false

		},

		clsType: function() {

			return 'is-' + this.$constants.text.typeSlug[this.text.type]

		},

		isImage: function() {

			return this.text.type === this.$constants.text.type.image

		},

		isCross: function() {

			return this.text.type === this.$constants.text.type.cross

		},

		isEmbed: function() {

			return this.text.type === this.$constants.text.type.embed

		},

		isTick: function() {

			return this.text.type === this.$constants.text.type.tick

		},

		isLocation: function() {

			return this.text.type === this.$constants.text.type.location

		},

		isTitle: function() {

			return this.text.type === this.$constants.text.type.title

		},

		isText: function() {

			return this.text.type === this.$constants.text.type.text

		},

		isBullet: function() {

			return this.$_.contains([this.$constants.text.type.tick, this.$constants.text.type.cross, this.$constants.text.type.check, this.$constants.text.type.phone, this.$constants.text.type.star, this.$constants.text.type.pin, this.$constants.text.type.link], this.text.type)

		},

		text: function() {

			return this.$_.findWhere(this.$store.getters['texts'], {
				id: parseInt(this.id)
			})

		},
		
		linkLocation: function() {

			return (this.location) ? {
				name: (this.$route.params.group) ? 'GroupLocation' : 'ItemLocation',
				params: {
					key: this.$route.params.key || null,
					location: this.location.slug
				}
			} : false

		},
		
		linkGroup: function() {

			return (this.group) ? {
				name: 'Group',
				params: {
					key: this.$route.params.key || null,
					category: this.groupCategory.slug,
					group: this.group.slug
				}
			} : false

		},

		textCross: function() {

			return nl2br(this.text.content) || this.$_.findWhere(this.$store.getters['groups'], {
				id: this.text.group
			}).title

		},

		locationServices: function() {

			var services = []

			this.$_.each(this.location.services, function(value) {

				if (this.$constants.locationServices[value]) services.push(this.$constants.locationServices[value])

			}.bind(this))

			return services.join(', ')

		}
			
	},

	methods: {

		groupCount: function(id) {

			return this.$_.where(this.$store.getters['items'], {
				group: id
			}).length

		}

	}

}

</script>

<style scoped>

.content {
	font-weight: 400;
}

.content:first-child {
	margin-top: 30px;
}

.content-title {
	color: #707070;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-top: 30px;
	margin-bottom: 10px;
	font-weight: 300;
	font-family: Roboto Slab,serif;
}

.content-image {
	width: 100%;
	height: auto;
	min-height: 200px;
	border-radius: 12px;
	background-color: #e7e7e7;
}

.content-bullet {
	display: flex;
	flex-direction: row;
}

.content-bullet-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.content-bullet.is-link .content-bullet-icon {
	background-image: url(~@/assets/content.link.png);
}

.content-bullet.is-pin .content-bullet-icon {
	background-image: url(~@/assets/content.pin.png);
}

.content-bullet.is-check .content-bullet-icon,
.content-bullet.is-checklist .content-bullet-icon {
	background-image: url(~@/assets/content.check.png);
}

.content-bullet.is-star .content-bullet-icon {
	background-image: url(~@/assets/content.star.png);
	background-size: 20px 20px
}

.content-bullet.is-phone .content-bullet-icon {
	background-image: url(~@/assets/content.phone.png);
}

.content-bullet.is-cross .content-bullet-icon {
	background-image: url(~@/assets/content.cross.png);
}

.content-bullet-text {
	color: #707070;
	font-size: 18px;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

.content-bullet.is-tick .content-bullet-text {
	font-weight: bold;
	font-size: 22px;
}

.content-bullet.is-link .content-bullet-text,
.content-bullet.is-cross.is-linked .content-bullet-text {
	text-decoration: underline;
}

.content-location {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 20px;
}

.content-location-logo {
	width: 64px;
	height: 64px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 20px;
	flex-shrink: 0;
	pointer-events: none;
}

.content-location-text {
	flex-grow: 1;
	pointer-events: none;
}

.content-location-text h3 {
	color: #707070;
	font-size: 24px;
	flex-grow: 1;
	margin-bottom: 5px;
}

.content-location-text h5 {
	color: #707070;
	font-size: 16px;
}

.content-text {
	color: #707070;
	font-size: 18px;
	flex-grow: 1;
	line-height: 24px;
	margin-bottom: 5px;
}

.content >>> a {
	color: #4082d3;
}

.content-embed >>> iframe {
	max-width: 100%;
	margin-bottom: 20px;
}

.checklist {
	display: grid;
	grid-space: 10px;
	grid-template-columns: 1fr 1fr 1fr;
}

.is-mobile .checklist,
.is-tablet .checklist {
	grid-template-columns: 1fr 1fr;
}

</style>
