<template>

<div class="groups">

	<com-group v-for="group in groups" :key="group.id" :category="category" :group="group" />

	<router-link :to="linkAll" class="groups-all" v-if="!showItems && groups.length && showViewAll">View All</router-link>

</div>

</template>

<script>

import comGroup from './groups/Group'

export default {

	props: ['category'],

	components: {
		'com-group': comGroup
	},

	data: function() {

		return {
			showViewAll: false
		}

	},
	
	computed: {

		showItems: function() {

			return this.$route.meta.all

		},

		linkAll: function() {

			return {
				name: 'CategoryAll',
				params: {
					key: this.$route.params.key || null,
					category: this.$_.findWhere(this.$store.getters['categories'], {
						id: this.category
					}).slug
				}
			}

		},

		groups: function() {

			return this.$_.where(this.$store.getters['groups'], {
				visible: 1,
				category: this.category
			})

		}

	}

}

</script>

<style scoped>

.groups-all {
	font-size: 16px;
	margin-top: 10px;
	color: #707070;
	text-align: right;
	width: 100%;
	display: block;
}	

</style>
