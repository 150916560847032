<template>
	
<div ref="banner" class="banner" :style="styleBanner" :class="{'is-background': !banner.type}" v-if="!$route.params.item && (!isDesktop || force) && banner.image">

	<img :src="$store.getters['base'] + banner.image" v-on:load="$emit('load')" class="banner-image" v-if="banner.image && banner.type" alt="Category banner image" />

	<div class="banner-item" :style="styleBannerItem" v-for="(item, index) in banner.texts" :key="index">

		<div class="banner-item-wrapper">

			<div class="banner-item-icon" :style="styleBannerItemIcon" :data-type="item.icon"></div>
			<div class="banner-item-text" :style="styleBannerItemText"><span v-html="item.text" :style="styleBannerItemTextSpan"></span></div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['banner', 'force'],

	data: function() {

		return {
			ratio: 1
		}

	},

	created: function() {

		this.computeRatio()

		this.$pubsub.$on('window.resize', this.computeRatio.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('window.resize', this.computeRatio.bind(this))

	},

	computed: {

		styleBanner: function() {

			return (this.banner.type) ? {
				backgroundColor: this.backgroundColor, 
				backgroundImage: this.backgroundImage
			} : {
				backgroundColor: this.backgroundColor, 
				backgroundImage: this.backgroundImage,
				minHeight: this.ratioNumber(120),
				borderRadius: this.ratioNumber(8),
				padding: this.ratioNumber(20, 10),
				backgroundSize: this.ratioNumber('auto', 120)
			}

		},

		styleBannerItem: function() {

			return {
				marginBottom: this.ratioNumber(5)
			}

		},

		styleBannerItemIcon: function() {

			return {
				height: this.ratioNumber(22),
				width: this.ratioNumber(26),
				backgroundSize: this.ratioNumber(14, 14),
				backgroundPosition: this.ratioNumber('50%', 4)
			}

		},

		styleBannerItemText: function() {

			return {
				paddingLeft: this.ratioNumber(26)
			}

		},

		styleBannerItemTextSpan: function() {

			return {
				padding: this.ratioNumber(2, 5, 2, 2),
				lineHeight: this.ratioNumber(22),
				fontSize: this.ratioNumber(15)
			}

		},

		backgroundImage: function() {

			return (this.banner.type) ? false : 'url(' + this.$store.getters['base'] + this.banner.image + ')'

		},

		backgroundColor: function() {

			return (this.banner.type) ? false : '#' + this.banner.background

		}

	},

	methods: {

		ratioNumber: function() {

			var result = []

			this.$_.each(arguments, function(value) {

				if (value.toString() === 'auto' || value.toString().indexOf('%') > 0) {

					result.push(value)

				} else {

					result.push((value * this.ratio).toString() + 'px')

				}

			}.bind(this))

			return result.join(' ')


		},
		
		computeRatio: function() {

			this.$nextTick(function() {

				this.ratio = this.$refs.banner.offsetWidth / 320

				this.$emit('load')

			}.bind(this))

		},

		text: function(text) {

			return '<span>' + text + '</span>'

		}

	}

}

</script>

<style scoped>

.banner {
	margin-bottom: 20px;
	width: 100%;
}

.banner.is-background {
	background-position: 100% 100%;
	background-repeat: no-repeat;
	width: calc(100% + 12px);
	margin-left: -6px;
}

.banner-image {
	width: 100%;
	height: auto;
	display: block;
}

.banner-item-wrapper {
	display: inline-flex;
	max-width: 71.4%;
}

.banner-item-icon {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: #fff;
	z-index: 2;
	background-repeat: no-repeat;
	flex-shrink: 0;
}

.banner-item-icon[data-type="tick"] {
	background-image: url(~@/assets/content.tick.png);
}

.banner-item-icon[data-type="cross"] {
	background-image: url(~@/assets/content.cross.png);
}

.banner-item-icon[data-type="check"] {
	background-image: url(~@/assets/content.check.png);
}

.banner-item-icon[data-type="bullet"] {
	background-image: url(~@/assets/content.bullet.png);
}

.banner-item-icon[data-type="star"] {
	background-image: url(~@/assets/content.star.png);
}

.banner-item-text {
	display: inline-block;
	color: #363636;
	z-index: 2;
	font-weight: 400;
	overflow: hidden;
}

.banner-item-text >>> span {
	background-color: #fff;
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

</style>