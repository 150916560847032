<template>

<router-link :to="link" class="group" :class="{'is-focussed': isFocussed}">

	<div class="group-head">

		<div class="group-head-icon" role="img" :alt="group.title" :style="{backgroundImage: 'url(' + $store.getters['base'] + group.image + ')'}"></div>
		
		<div class="group-head-text">{{ group.title }}</div>

	</div>
	
	<div class="group-items" v-if="showItems">

		<div class="group-items-item" role="img" v-for="item in items" :key="item.id" :style="{backgroundImage: 'url(' + $store.getters['base'] + item.image + ')'}"></div>

	</div>

</router-link>

</template>

<script>

export default {

	props: ['group', 'category'],

	computed: {

		isFocussed: function() {

			return this.$store.getters['group/focus'] === this.group.id

		},

		count: function() {

			return this.$_.filter(this.$store.getters['items'], {
				group: this.group.id
			}).length
			
		},

		showItems: function() {

			return this.$route.meta.all

		},

		items: function() {

			return this.$_.where(this.$store.getters['items'], {
				group: this.group.id,
				visible: 1
			})

		},

		link: function() {

			return {
				name: 'Group',
				params: {
					key: this.$route.params.key || null,
					category: this.$_.findWhere(this.$store.getters['categories'], {
						id: this.category
					}).slug,
					group: this.group.slug
				}
			}

		}

	}

}

</script>

<style scoped>

.group {
	margin-bottom: 1px;
	display: block;
}

.group.is-focussed {
	background-color: #eee;
}

.group-head {
	display: flex;
	flex-direction: row;
	min-height: 51px;
	align-items: center;
}

.group-head-icon {
	width: 51px;
	height: 51px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin-right: 13px;
	background-color: #F9F9F9;
	flex-shrink: 0;
}

.group-head-text {
	flex-grow: 1;
	font-size: 20px;
	padding: 10px 0px;
	line-height: 24px;
	padding-right: 54px;
	font-weight: bold;
	color: #707070;
	background-image: url(~@/assets/chevron.right.png);
	background-repeat: no-repeat;
	background-position: calc(100% - 10px) 50%;
	background-size: 8px auto;
}

.is-desktop .group-head-text {
	background-image: none;
}

.group-head-text small {
	font-size: 16px;
	font-weight: normal;
}

.group-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding-bottom: 30px;
}

.group-items-item {
	width: calc(33.3% - 10px);
	height: 100px;
	margin: 5px;
}

</style>
