import pubsub from '@/pubsub'
import vhcheck from 'vh-check'

var forceMobile = document.getElementById('app').getAttribute('data-force-mobile') || false

function windowResize() {

	var height = window.innerHeight
	var width = window.innerWidth

	var breakpoint = process.env.VUE_APP_BREAKPOINT

	window.sizeData = {
		width: width,
		height: height,
		is: {
			mobile: forceMobile ? true : width < breakpoint,
			tablet: forceMobile ? false : width >= breakpoint && width < breakpoint,
			desktop: forceMobile ? false : width >= breakpoint
		}
	}

	vhcheck()

	pubsub.$emit('window.resize', window.sizeData)

}

pubsub.$on('window.initiated', function() {

	vhcheck()
	windowResize()

})

window.addEventListener('resize', windowResize)
window.addEventListener('orientationchange', windowResize)

export default {

	data: function() {
		
		return {
			window: {
				width: 0,
				height: 0,
				is: {
					mobile: false,
					tablet: false,
					desktop: false
				}
			}
		}

	},

	computed: {

		isDesktop: function() {

			return this.window.is.desktop && !this.$store.getters['mobileOnly']

		}

	},

	created: function() {

		this.window = window.sizeData

		pubsub.$on('window.resize', this.onWindowResize.bind(this))

	},

	beforeDestroy: function() {

		pubsub.$off('window.resize', this.onWindowResize.bind(this))

	},

	methods: {

		onWindowResize: function(data) {

			this.window = data

		}

	}

}