<template>

<div class="head" :class="{'is-group': isGroup}">

	<com-rbc v-if="isRBC" />

	<com-search />

	<div class="head-content" v-if="isGroup">

		<router-link :to="backLink" class="head-back">&lt; see all guidelines</router-link>

	</div>

</div>

</template>

<script>

import comSearch from './head/Search'
import comRBC from './head/RBC'

export default {

	props: ['isEmbed'],

	components: {
		'com-search': comSearch,
		'com-rbc': comRBC
	},

	computed: {

		isRBC: function() {

			return false //this.$store.getters['guide'].rbc.enabled && !this.isEmbed

		},

		isGroup: function() {

			return this.$route.meta.isGroup

		},

		category: function() {

			return (this.isGroup) ? this.$_.findWhere(this.$store.getters['categories'], {
				id: this.$store.getters['category']
			}) : false

		},

		title: function() {

			return  this.$_.findWhere(this.$store.getters['categories'], {
				id: this.$store.getters['category']
			}).title

		},

		backLink: function() {

			return {
				name: 'Category',
				params: {
					key: this.$route.params.key || null
				}
			}

		},

		backgroundColor: function() {

			return (this.$route.meta.isGroup) ? this.category.background : this.$store.getters['categories'][0].background

		}

	}

}

</script>

<style scoped>

.head {
	height: auto;
}

.head-back {
	padding: 20px 0px 0px 14px;
	font-size: 16px;
	letter-spacing: 1px;
	display: block;
	color: #333;
}

.head-icon {
	width: 70px;
	height: 70px;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	flex-shrink: 0;
}

.head-text {
	font-size: 26px;
	line-height: 34px;
	color: #fff;
	font-weight: bold;
	letter-spacing: 0.1px;
	padding-right: 20px;
	flex-grow: 1;
}

</style>
