<template>

<component :to="{name: 'Category'}" :is="element" class="tick">

	<div class="tick-icon" v-if="!category" />
	
	<div v-if="category" class="tick-group" :style="{backgroundImage: 'url(' + $store.getters['base'] + category.icon + ')', backgroundColor: '#' + category.background}" />

	<h1 class="tick-text" v-html="title" />

</component>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		element: function() {

			return (this.group) ? 'router-link' : 'div'

		},

		title: function() {

			return this.item.content || this.group.title

		},

		group: function() {

			return (this.item.group) ? this.$_.findWhere(this.$store.getters['groups'], {
				id: parseInt(this.item.group)
			}) : false

		},

		category: function() {

			if (this.$route.params.category) {

				var category = this.$_.findWhere(this.$store.getters['categories'], {
					slug: this.$route.params.category
				})
				
				return category

			} else {

				return false

			}

		}

	}

}

</script>

<style scoped>

.tick {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
	align-items: center;
}

.tick-group {
	width: 32px;
	height: 32px;
	margin-right: 10px;
	border-radius: 4px;
	flex-shrink: 0;
	background-size: 16px auto;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.tick-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~@/assets/content.tick.png);
}

.tick-text {
	color: #707070;
	flex-grow: 1;
	font-weight: bold;
	font-size: 22px;
}

.tick >>> a {
	color: #4082d3;
}

</style>
