<template>

<div class="rbc">

	<div class="rbc-toggle"><span></span><span></span><span></span></div>

	<div class="rbc-logo"></div>

	<div class="rbc-title">{{ $store.getters['guide'].rbc.title }}</div>

	<div class="rbc-menu">
		<div class="rbc-menu-item is-active">Guide</div>
		<div class="rbc-menu-item">Quiz</div>
		<div class="rbc-menu-item">Brief</div>
		<div class="rbc-menu-item">Schedule</div>
	</div>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.rbc {
	height: 162px;
	background-image: url(~@/assets/rbc.bkg.png);
	background-position: 50% 0px;
}

.rbc-menu {
	left: 0px;
	position: absolute;
	bottom: 0px;
	height: 32px;
	background-color: #fff;
	width: 100%;
	display: flex;
	box-shadow: 0 4px 2px 0 rgb(0 0 0 / 15%);
	z-index: 2;
}

.rbc-menu-item {
	flex-grow: 1;
	flex-shrink: 0;
	line-height: 32px;
	text-transform: uppercase;
	text-align: center;
	color: #7d7d7d;
	font-size: 13px;
	font-family: Lato,helvetica,arial;
    font-weight: 700;
	border-left: 1px solid #ebebeb;
}

.rbc-menu-item:first-child {
	border-left-width: 0px;
}

.rbc-menu-item.is-active {
	color: #00aeef;
}

.rbc-toggle {
	position: absolute;
	left: 8px;
	top: 8px;
	background-color: #fff;
	width: 32px;
	height: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rbc-toggle span {
	display: block;
	width: 16px;
	height: 2px;
	background-color: #00aeef;
	margin: 1.5px 0px;
}

.rbc-logo {
	width: 164px;
	height: 60px;
	position: absolute;
	top: 0px;
	left: 50%;
	margin-left: -82px;
	background-image: url(~@/assets/rbc.logo.png);
	background-position: 50% 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.rbc-title {
	position: absolute;
	left: 0px;
	width: 100%;
	top: 76px;
	text-align: center;
	font-weight: 300;
    color: #363636;
    font-size: 30px;
	font-family: Roboto Slab,serif;
}

</style>
