<template>
		
<div class="app-content">

	<div class="content-wrapper">

		<div :ref="'category-' + category.id" :id="'category-' + category.id" class="content-category" v-for="category in categories" :key="category.id">

			<com-banner :banner="category.banner" :force="true" />

			<router-link :id="'group-' + item.group" v-for="item in items(category.id)" v-on:mouseover.native="itemEnter(item)" v-on:mouseleave.native="itemLeave(item)" :to="linkItem(item)" class="content-category-item" :class="{'is-selected': item.group === focusGroup, 'is-excluded': item.exclude}" :key="item.id" :style="{backgroundImage: 'url(' + $store.getters['base'] + item.image + ')', borderColor: '#' + category.background}">

				<span>{{ item.title }}</span>

				<div class="content-category-item-exclude" v-if="item.exclude" />

			</router-link>

		</div>

	</div>

</div>

</template>

<script>

import comBanner from './common/Banner'
var VueScrollTo = require('vue-scrollto')

export default {

	components: {
		'com-banner': comBanner
	},

	data: function() {

		return {
			focus: false,
			ignoreScroll: false
		}

	},

	created: function() {

		window.addEventListener('scroll', this.onScroll.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('scroll', this.onScroll.bind(this))

	},

	watch: {

		routePath: function() {

			this.$nextTick(function() {

				if (this.$route.params.scrolled) return false

				if (this.$store.getters['group']) {

					VueScrollTo.scrollTo(document.getElementById('group-' + this.$store.getters['group']), 1000, {
						offset: -20,
						onStart: function() {

							this.ignoreScroll = true

						}.bind(this),
						onDone: function() {

							this.ignoreScroll = false

						}.bind(this)
					})

				} else if (this.$store.getters['category']) {

					VueScrollTo.scrollTo(document.getElementById('category-' + this.$store.getters['category']), 1000, {
						offset: -20,
						onStart: function() {

							this.ignoreScroll = true

						}.bind(this),
						onDone: function() {

							this.ignoreScroll = false

						}.bind(this)
					})

				}

			})

		}

	},

	computed: {

		routePath: function() {

			return this.$route.path

		},

		focusGroup: function() {

			return (this.focus) ? this.focus : this.$store.getters['group']

		},

		categories: function() {

			return this.$_.where(this.$store.getters['categories'], {
				visible: 1
			})

		}

	},

	methods: {

		onScroll: function() {

			if (this.ignoreScroll) return false

			var currentCategory = -1

			this.$_.each(this.$refs, function(item) {

				if (item.length) {

					if (item[0].getBoundingClientRect().top < 0) {

						currentCategory++

					}

				}

			}.bind(this))

			if (currentCategory == -1) currentCategory = 0

			if (this.categories[currentCategory].id !== this.$store.getters['category']) {

				this.$router.push({
					name: 'Category',
					params: {
						key: this.$route.params.key || null,
						category: this.categories[currentCategory].slug,
						scrolled: true
					}
				}).catch(function() {})

			}

		},

		items: function(category) {

			var items = []

			var groups = this.$_.where(this.$store.getters['groups'], {
				category: category
			})

			this.$_.each(groups, function(group) {

				this.$_.each(this.$_.filter(this.$store.getters['items'], function(item) {

					return item.visible && group.id === item.group

				}.bind(this)), function(item) {

					items.push(item)

				})

			}.bind(this))

			return items

		},

		itemEnter: function(item) {

			this.focus = item.group

			this.$store.commit('group/focus', this.focus)

		},

		itemLeave: function() {

			this.focus = false

			this.$store.commit('group/focus', this.focus)

		},

		linkItem: function(item) {

			if (item.exclude) {

				var alternate = this.$_.findWhere(this.$store.getters['groups'], {
					id: item.alternate
				})

				if (!alternate) return {}

				var category = this.$_.findWhere(this.$store.getters['categories'], {
					id: alternate.category
				})

				if (!category) return {}

				return {
					name: 'Group',
					params: {
						key: this.$route.params.key || null,
						category: category.slug,
						group: alternate.slug
					}
				}

			} else {

				var group = this.$_.findWhere(this.$store.getters['groups'], {
					id: item.group
				})

				var groupcategory = this.$_.findWhere(this.$store.getters['categories'], {
					id: group.category
				})

				return {
					name: 'Item',
					params: {
						key: this.$route.params.key || null,
						category: groupcategory.slug,
						group: group.slug,
						item: item.slug
					}
				}

			}

		}

	}

}

</script>

<style scope>

.app-content {
	padding: 20px 20px 20px 20px;
	padding-left: 420px;
	width: 100%;
	background-color: fff;
	overflow: auto;
	scroll-behavior: smooth;
	z-index: 1;
}

.content-image {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.content-category {
	display: flex;
	flex-wrap: wrap;
}

.content-category-item {
	width: 200px;
	height: 200px;
	background-color: #F9F9F9;
	border-radius: 50%;
	border: 2px solid #F9F9F9;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 0px 20px 20px 0px;
}

.content-category-item {
	opacity: 0;
	position: absolute;
	pointer-events: none;
}

.content-category-item:not(.is-selected) {
	border-color: transparent!important;
}

.content-category-item.is-excluded {
	background-color: #FFECED;
}

.content-category-item-exclude {
	background-image: url(~@/assets/content.cross.png);
	width: 20px;
	height: 20px;
	background-size: 17px 17px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: absolute;
	left: 4px;
	top: 4px;
}

</style>
