import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'underscore'
import axios from 'axios'
import slugify from 'slugify'
import api from '@/api'
import constants from '@/constants'
import live from '@/live'

Vue.use(Vuex)

export default new Vuex.Store({

	modules: {
	},

	state: {
		locale: 'en',
		guide: {},
		categories: [],
		groups: [],
		texts: {},
		locations: {},
		dictionary: [],
		items: [],
		terms: [],
		category: 0,
		mobileOnly: 0,
		group: 0,
		groupFocus: 0,
		item: 0,
		location: 0,
		key: '',
		primary: 0,
		base: ''
	},

	getters: {

		locale: function(state) {

			return state.locale

		},

		guide: function(state) {

			return state.guide

		},

		mobileOnly: function(state) {

			return state.mobileOnly

		},

		categories: function(state) {

			return state.categories

		},

		groups: function(state) {

			return state.groups

		},

		items: function(state) {

			return state.items

		},

		dictionary: function(state) {

			return state.dictionary

		},

		terms: function(state) {

			return state.terms

		},

		texts: function(state) {

			return state.texts

		},

		locations: function(state) {

			return state.locations

		},

		slides: function(state) {

			return state.slides

		},

		category: function(state) {

			return state.category

		},

		group: function(state) {

			return state.group

		},

		'group/focus': function(state) {

			return state.groupFocus

		},

		item: function(state) {

			return state.item

		},

		location: function(state) {

			return state.location

		},

		key: function(state) {

			return state.key

		},

		base: function(state) {

			return state.base

		}

	},

	mutations: {

		locale: function(state, value) {

			state.locale = value

		},

		key: function(state, value) {

			state.key = value

		},

		primary: function(state, value) {

			state.primary = value

		},

		mobileOnly: function(state, value) {

			state.mobileOnly = value

		},

		guide: function(state, value) {

			Vue.set(state, 'guide', value)

		},

		category: function(state, value) {

			state.category = value

		},

		group: function(state, value) {

			state.group = value

		},

		'group/focus': function(state, value) {

			state.groupFocus = value

		},

		item: function(state, value) {

			state.item = value

		},

		location: function(state, value) {

			state.location = value

		}

	},

	actions: {

		init: function(context, e) {

			context.commit('key', e.key)
			context.commit('primary', e.primary)

			axios.defaults.headers.common['rbc-guide'] = e.key

			live.init()

			live.$on('updated', function() {

				context.dispatch('load')

			})

			return context.dispatch('load')
	
		},

		load: function(context) {

			return new Promise(function(resolve) {

				var params = {}

				if (context.state.primary) params.primary = context.state.primary
					
				api.get('guide/' + context.getters['key'], params).then(function(response) {

					context.state.categories = response.categories
					Vue.set(context.state, 'guide', response.guide)
					context.state.groups = response.groups
					context.state.items = response.items
					context.state.texts = response.texts
					context.state.locations = response.locations
					context.state.base = response.base

					var terms = []
					var dictionary = []

					_.each({
						//category: 'categories',
						group: 'groups',
						item: 'items'
						//location: 'locations'
					}, function(store, key) {
		
						_.each(context.getters[store], function(item) {

							var title = slugify(item.title, {
								replacement: ' ',
								remove: /[*+~.()'"!:@]/g,
								strict: true,
								lower: true
							})
		
							terms.push({
								id: item.id,
								text: title,
								type: constants.type[key]
							})

							_.each(title.split(' '), function(part) {

								dictionary.push(part)

							})
		
							_.each(item.terms, function(term) {

								term = slugify(term, {
									replacement: ' ',
									remove: /[*+~.()'"!:@]/g,
									strict: true,
									lower: true
								})
		
								terms.push({
									id: item.id,
									text: term,
									type: constants.type[key]
								})

								_.each(term.split(' '), function(part) {
	
									dictionary.push(part)
	
								})
		
							})
		
						})
		
					})

					context.state.terms = terms
					context.state.dictionary = dictionary
		
					if (!context.state.category) context.state.category = response.categories[0].id

					resolve()

				})

			})

		}

	}

})
