import Vue from 'vue'
import Router from 'vue-router'

import Categories from '@/components/Categories'
import Group from '@/components/Group'

Vue.use(Router)

var base = document.getElementById('app').getAttribute('data-base') || '/'

export default new Router({
	mode: 'history',
	base: base,
	routes: [
		{ name: 'Categories', path: '/:key([a-zA-Z0-9]{32})?/', meta: { }, component: Categories },
		{ name: 'Location', path: '/:key([a-zA-Z0-9]{32})?/locations/:location', meta: { isLocation: true }, component: Categories },
		{ name: 'Category', path: '/:key([a-zA-Z0-9]{32})?/:category', meta: { }, component: Categories },
		{ name: 'CategoryAll', path: '/:key([a-zA-Z0-9]{32})?/:category/all', meta: { all: true }, component: Categories },
		{ name: 'Group', path: '/:key([a-zA-Z0-9]{32})?/:category/:group', meta: { isGroup: true }, component: Group },
		{ name: 'Item', path: '/:key([a-zA-Z0-9]{32})?/:category/:group/:item', meta: { isGroup: true }, component: Group },
		{ name: 'GroupLocation', path: '/:key([a-zA-Z0-9]{32})?/:category/:group/locations/:location', meta: { isGroup: true, isLocation: true }, component: Group },
		{ name: 'ItemLocation', path: '/:key([a-zA-Z0-9]{32})?/:category/:group/:item/locations/:location', meta: { isGroup: true, isLocation: true }, component: Group },
	]
})