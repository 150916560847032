<template>

<router-link :to="link" class="group" v-if="group">

	<div class="group-image" :style="{backgroundImage: 'url(' + $store.getters['base'] + group.image + ')'}"></div>
	<div class="group-text">
			
		<h3>{{ group.title }}</h3>
			
	</div>

</router-link>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		count: function() {

			return this.$_.where(this.$store.getters['items'], {
				group: parseInt(this.item.group)
			}).length

		},

		group: function() {

			return (this.item.group) ? this.$_.findWhere(this.$store.getters['groups'], {
				id: parseInt(this.item.group)
			}) : false

		},

		category: function() {

			return (this.item.group) ? this.$_.findWhere(this.$store.getters['categories'], {
				id: this.group.category
			}) : false

		},

		link: function() {

			return (this.group) ? {
				name: 'Group',
				params: {
					key: this.$route.params.key || null,
					category: this.category.slug,
					group: this.group.slug
				}
			} : false

		}

	}

}

</script>

<style scoped>

.group {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 20px;
}

.group-image {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	flex-shrink: 0;
}

.group-text {
	flex-grow: 1;
}

.group-text h3 {
	color: #707070;
	font-size: 22px;
	flex-grow: 1;
}

.group-text h3 small {
	font-size: 16px;
}

</style>
