<template>

<div class="category" :class="{'is-expanded': expanded}" :style="{height: height.toString() + 'px'}">

	<router-link :to="(expanded) ? {name: 'Categories', query: { collapse: 1 }} : link" class="category-head" :style="{backgroundColor: '#' + category.background}">

		<div class="category-head-icon" role="img" :alt="category.title" :style="{backgroundImage: 'url(' + $store.getters['base'] + category.icon + ')'}"></div>
		<div class="category-head-text" :class="{'is-small': window.width <= 346}">{{ category.title }}</div>

	</router-link>

	<div class="category-content" ref="content">

		<com-banner :banner="category.banner" v-on:load="calculateHeight()" />

		<div class="category-content-text" v-if="category.text && !isDesktop" v-html="category.text"></div>

		<com-groups :category="category.id" />

	</div>

</div>

</template>

<script>

import comGroups from './category/Groups'
import comBanner from './../common/Banner'

export default {

	props: ['category', 'expanded'],

	components: {
		'com-groups': comGroups,
		'com-banner': comBanner
	},

	data: function() {

		return {
			height: 106
		}

	},

	watch: {

		expanded: function() {

			this.calculateHeight()

		}

	},

	created: function() {

		this.calculateHeight()

		this.$pubsub.$on('window.resize', this.calculateHeight.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('window.resize', this.calculateHeight.bind(this))

	},

	computed: {

		link: function() {

			return {
				name: 'Category',
				params: {
					key: this.$route.params.key || null,
					category: this.category.slug
				}
			}

		}

	},

	methods: {

		calculateHeight: function() {

			this.$nextTick(function() {

				this.height = this.$refs.content.offsetHeight + 106

				this.$emit('loaded')

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.category {
	overflow: hidden;
	transition: all 200ms linear;
}

.category:not(.is-expanded) {
	height: 106px!important;
}

.is-desktop .category.is-expanded {
	flex-grow: 0;
}

.category-head {
	height: 96px;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-shadow: 0px 3px 6px #00000029;
	margin-bottom: 10px;
	cursor: pointer;
}

.category-head-icon {
	width: 70px;
	height: 70px;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	flex-shrink: 0;
}

.category-head-text {
	font-size: 24px;
	line-height: 32px;
	color: #fff;
	font-weight: bold;
	letter-spacing: 0.1px;
	padding-right: 20px;
	flex-grow: 1;
}

.category-head-text.is-small {
	font-size: 20px;
    line-height: 26px;
}

.category-content {
	padding: 0px 10px 20px 10px;
}

.category-content-text {
	font-size: 18px;
	line-height: 22px;
	color: #71706F;
	margin-bottom: 20px;
}

.category-content-text >>> em,
.category-content-text >>> i {
	font-style: italic;
}

.category-content-text >>> strong,
.category-content-text >>> b {
	font-weight: bold;
}

.category-content-text >>> a {
	color: #2E75CC;
	border-bottom: 1px dashed #2E75CC;
}

.category-content-text >>> a:hover {
	text-decoration: underline;
}

</style>
