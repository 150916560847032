<template>

<div role="button" tabindex="0" class="language" v-on:click="expanded = !expanded" :class="{'is-loading': loading}">

	<div v-if="!loading" class="language-code">{{ locale }}</div>
	<i v-if="!loading" class="fa fa-caret-down"></i>
	<i v-if="loading" class="fas fa-spinner fa-spin"></i>

	<div class="language-options" bablic-exclude v-if="expanded">

		<div role="button" class="language-options-item" v-for="language in languages" :key="language.key" v-on:click.stop="onLanguageClick(language.key)">{{ language.name }}</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			locale: 'en',
			loading: false,
			expanded: false,
			languages: [
				{key: 'en', name: 'English'},
				{key: 'es', name: 'Spanish'}
			]
		}

	},

	created: function() {

		// eslint-disable-next-line
		bablic.on('locale', this.onChanged.bind(this))
		
		// eslint-disable-next-line
		bablic.on('done', this.onReady.bind(this))

		this.$store.commit('locale', this.locale)

	},

	methods: {

		onLanguageClick: function(key) {

			this.expanded = false

			if (key !== this.locale) {
				
				this.loading = true

				// eslint-disable-next-line
				bablic.redirectTo(key)

			}

		},

		onChanged: function() {

			// eslint-disable-next-line
			this.locale = bablic.getLocale()

			this.loading = false

			this.$store.commit('locale', this.locale)

		},

		onReady: function() {

			// eslint-disable-next-line
			this.languages = bablic.languages.get()

		}

	}

}

</script>

<style scoped>

.language {
	flex-shrink: 0;
	width: 48px;
	margin-left: 10px;
	background-color: #666;
	color: #fff;
	border-radius: 17px;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.language:hover {
	background-color: #777;
}

.language.is-loading {
	pointer-events: none;
}

.language-code {
	font-size: 16px;
	margin-right: 5px;
	text-transform: uppercase;
}

.language .fa {
	font-size: 12px;
	margin-top: 2px;
}

.language-options {
	position: absolute;
	top: 35px;
	right: 0px;
	background-color: #666;
	z-index: 1000;
	padding: 10px 0px;
	border-radius: 17px;
	overflow: hidden;
}

.language-options-item {
	font-size: 14px;
	padding: 5px 15px;
	cursor: pointer;
}

.language-options-item:hover {
	background-color: #777;
}

</style>
