<template>

<div class="search">

	<div class="search-input">
		<input id="searchbox" type="text" :disabled="is.adding" v-model="text" class="search-input-box" v-on:focus="onInputFocus" v-on:blur="onInputBlur" />
		<label for="searchbox">Search</label>
		<div class="search-input-placeholder" :class="{'is-finished': placeholderFinished}" v-if="!text && !placeholderHide">{{ placeholder }}</div>
	</div>

	<a :href="download" target="_blank" class="search-download" title="Click to download guide" v-if="download"><span>Download Guide</span></a>

	<com-language />

	<div class="search-results" v-if="terms.length">

		<router-link :to="termLink(term)" class="search-results-item" v-for="(term, index) in terms" :key="index" v-html="termHighlight(term)"></router-link>

	</div>

	<div class="search-results" v-if="(!terms.length && text) || is.adding">

		<div class="search-results-item" v-if="!is.requested" :class="{'is-adding': is.adding}" v-on:click="onAddClick">Nothing found. Add this item to the guide? <i v-if="is.adding" class="fas fa-spinner fa-spin"></i></div>

		<div class="search-results-item is-adding" v-if="is.requested" v-on:click="onCloseClick">Thank you, your request has been submitted. <i class="fa fa-check"></i></div>

	</div>

</div>

</template>

<script>

import spelling from 'spelling'
import slugify from 'slugify'

import comLanguage from './Language'

export default {

	components: {
		'com-language': comLanguage
	},

	data: function() {

		return {
			is: {
				adding: false,
				requested: false
			},
			text: '',
			dictionary: false,
			terms: [],
			term: '',
			delay: false,
			placeholderHide: false,
			placeholderTemplate: 'Search...',
			placeholderPosition: 0
		}

	},

	created: function() {

		this.dictionary = new spelling(this.$store.getters['dictionary'])

		this.onPlaceholderAnimate()

	},

	watch: {

		route: {

			deep: true,

			handler: function() {

				this.text = ''

			}

		},

		text: function() {

			this.onChange()

		}

	},

	computed: {

		download: function() {
		
			return (this.$store.getters['guide'].pdf.en) ? process.env.VUE_APP_API + '../' + (this.$store.getters['guide'].pdf[this.$store.getters['locale']] || this.$store.getters['guide'].pdf.en) : false

		},

		placeholder: function() {

			return this.placeholderTemplate.substring(0, this.placeholderPosition)

		},

		route: function() {

			return this.$route

		}

	},

	methods: {

		onInputFocus: function() {
		
			this.placeholderHide = true
			this.placeholderPosition = 0
			clearTimeout(this.placeholderTimeout)
		
		},

		onInputBlur: function() {
		
			this.placeholderHide = false

			this.placeholderTimeout = this.$_.delay(function() {

				this.onPlaceholderAnimate()
			
			}.bind(this), 400)
		
		},

		onPlaceholderAnimate: function() {
		
			this.placeholderPosition = (this.placeholderPosition < this.placeholderTemplate.length) ? this.placeholderPosition + 1 : 0
			this.placeholderFinished = this.placeholderPosition === this.placeholderTemplate.length

			this.placeholderTimeout = this.$_.delay(function() {

				this.onPlaceholderAnimate()
			
			}.bind(this), (this.placeholderFinished) ? 4000 : 200)
		
		},

		onAddClick: function() {

			this.is.adding = true

			this.$api.post('request', {
				term: this.text
			}).then(function() {

				this.is.adding = false
				this.is.requested = true

				this.delay = this.$_.delay(this.onCloseClick.bind(this), 3000)

			}.bind(this))

		},

		onCloseClick: function() {

			clearTimeout(this.delay)
			this.text = ''

		},

		termLink: function(term) {

			var to = {
				params: {
					key: this.$route.params.key || null
				},
				name: ''
			}

			if (term.type === this.$constants.type.group) {

				to.name = 'Group'

				to.params.group = term.group
				to.params.category = term.category

			} else if (term.type === this.$constants.type.location) {

				to.name = 'Location'
				to.params.location = term.location

			}

			return to 

		},

		termHighlight: function(term) {

			var highlight = ['<b>' + term.text + '</b>']

			if (term.text.indexOf( slugify(this.text, {
					replacement: ' ',
					remove: /[*+~.()'"!:@]/g,
					lower: true
				})) === -1) {

				highlight.push(term.matches[0].text)

			}

			return highlight.join(', ')

		},

		onChange: function() {

			this.is.requested = false

			if (this.text !== '') {

				var searchTerms = slugify(this.text, {
					replacement: ' ',
					remove: /[*+~.()'"!:@]/g,
					lower: true
				}).split(' ')

				var resultCandidates = {}, termText, variantScore, termMatches = [], termScore, searchVariants

				this.$_.each(searchTerms, function(searchTerm) {

					searchVariants = (searchTerm.length > 3) ? this.$_.pluck(this.dictionary.lookup(searchTerm, {
						forceSuggest: true
					}).suggestions, 'word') : []

					this.$_.each(this.$store.getters['terms'], function(term) {

						termScore = 0

						termText = term.text + ' '

						if(termText.indexOf(searchTerm + ' ') === 0) {

							termScore = (searchTerm.length > 1) ? 24 : 12

						} else if(termText.indexOf(' ' + searchTerm + ' ') > 0) {

							termScore = (searchTerm.length > 1) ? 16 : 8

						} else if(termText.indexOf(searchTerm) === 0) {

							termScore = (searchTerm.length > 1) ? 12 : 6

						} else if(termText.indexOf(searchTerm) > 0) {

							termScore = (searchTerm.length > 1) ? 8 : 4

						} else {

							this.$_.each(searchVariants, function(variant) {

								if (variant !== searchTerm) {

									variantScore = 0

									if(termText.indexOf(variant + ' ') === 0) {

										variantScore = 6

									} else if(termText.indexOf(' ' + variant + ' ') > 0) {

										variantScore = 4

									} else if(termText.indexOf(variant) === 0) {

										variantScore = 2

									} else if(termText.indexOf(variant) > 0) {

										variantScore = 1

									}

									if (variantScore > termScore) termScore = variantScore

								}

							})

						}

						if (termScore) {

							termMatches.push({
								searchTerm: searchTerm,
								term: term,
								score: termScore
							})

						}

					}.bind(this))

				}.bind(this))

				termMatches = this.$_.sortBy(termMatches, 'score').reverse()

				var key = ''

				this.$_.each(termMatches, function(termMatch) {

					key = termMatch.term.id + '-' + termMatch.term.type

					resultCandidates[key] = resultCandidates[key] || {
						id: termMatch.term.id,
						type: termMatch.term.type,
						count: 0,
						score: 0,
						matchTerms: [],
						searchTerms: []
					}

					if(!this.$_.contains(resultCandidates[key].searchTerms, termMatch.searchTerm)) {

						resultCandidates[key].count++
						resultCandidates[key].score += termMatch.score 
						resultCandidates[key].matchTerms.push({
							score: termMatch.score,
							text: termMatch.term.text
						})
						resultCandidates[key].searchTerms.push(termMatch.searchTerm)

						resultCandidates[key].matchTerms = this.$_.sortBy(resultCandidates[key].matchTerms, 'score').reverse()

					}

				}.bind(this))

				resultCandidates = this.$_.sortBy(resultCandidates, 'count').reverse()
				resultCandidates = this.$_.sortBy(resultCandidates, 'score').reverse()

				var highestCount = this.$_.max(resultCandidates, function(candidate) {

					return candidate.count

				})

				resultCandidates = this.$_.filter(resultCandidates, function(candidate) {

					return candidate.count === highestCount.count && candidate.score > highestCount.score * 0.75

				})

				this.terms = []

				var groupsAdded = []

				this.$_.each(resultCandidates, function(candidate) {

					var category, group, item//, location

					if (candidate.type === this.$constants.type.category) {

						category = this.$_.findWhere(this.$store.getters['categories'], {
							id: candidate.id
						})

						if (category.searchable) {

							this.$_.each(this.$_.where(this.$store.getters['groups'], {
								category: category.id
							}), function(categoryGroup) {

								if (!this.$_.contains(groupsAdded, categoryGroup.id)) {

									groupsAdded.push(categoryGroup.id)

									this.terms.push({
										type: this.$constants.type.group,
										category: category.slug,
										group: categoryGroup.slug,
										matches: candidate.matchTerms,
										text: slugify(categoryGroup.title, {
											replacement: ' ',
											remove: /[*+~.()'"!:@]/g,
											strict: true,
											lower: true
										})
									})

								}

							}.bind(this))
							
						}

					} else if (candidate.type === this.$constants.type.group) {

						if (!this.$_.contains(groupsAdded, candidate.id)) {

							groupsAdded.push(candidate.id)

							group = this.$_.findWhere(this.$store.getters['groups'], {
								id: candidate.id
							})

							category = this.$_.findWhere(this.$store.getters['categories'], {
								id: group.category
							})

							if (category.searchable && group.searchable) {

								this.terms.push({
									type: this.$constants.type.group,
									category: category.slug,
									group: group.slug,
									matches: candidate.matchTerms,
									text: slugify(group.title, {
										replacement: ' ',
										remove: /[*+~.()'"!:@]/g,
										strict: true,
										lower: true
									})
								})

							}

						}

					} else if (candidate.type === this.$constants.type.item) {

						item = this.$_.findWhere(this.$store.getters['items'], {
							id: candidate.id
						})

						if (!this.$_.contains(groupsAdded, item.group)) {

							groupsAdded.push(item.group)

							group = this.$_.findWhere(this.$store.getters['groups'], {
								id: item.group
							})

							category = this.$_.findWhere(this.$store.getters['categories'], {
								id: group.category
							})

							if (category.searchable && group.searchable) {

								this.terms.push({
									type: this.$constants.type.group,
									category: category.slug,
									group: group.slug,
									matches: candidate.matchTerms,
									text: slugify(group.title, {
										replacement: ' ',
										remove: /[*+~.()'"!:@]/g,
										strict: true,
										lower: true
									})
								})

							}

						}

					} else if (candidate.type === this.$constants.type.location) {

						//

					}

				}.bind(this))

			} else {

				this.terms = []

			}

		}

	}

}

</script>

<style scoped>

.search {
	height: 64px;
	background-color: #e3e3e3;
	padding: 15px;
	display: flex;
}

.search-input {
	background-color: #fff;
	border-radius: 17px;
	height: 34px;
	flex-grow: 1;
	width: 100%;
	color: #707070;
	background-image: url(~@/assets/search.svg);
	background-size: 20px auto;
	background-position: calc(100% - 10px) 50%;
	background-repeat: no-repeat;
}

.search-input label {
	opacity: 0;
    pointer-events: none;
    position: absolute;
}

.search-input-box {
	padding: 0px 40px 0px 20px;
	font-size: 16px;
	line-height: 34px;
	height: 34px;
}

.search-input-placeholder {
	color: #666;
	left: 0px;
	padding: 0px 0px 0px 20px;
	top: 0px;
	font-size: 16px;
	pointer-events: none;
	line-height: 20px;
	margin-top: 7px;
	height: 20px;
	position: absolute;
	border-right: 1px solid #666;
}

.search-input-placeholder.is-finished {
	animation: 1s linear infinite blink-animation;
}

@keyframes blink-animation {
	to {
		border-right: 0px;
	}
}

.search-results {
	position: absolute;
	top: 64px;
	left: 0px;
	padding-bottom: 20px;
	z-index: 1000;
	background-color: #e3e3e3;
	width: 100%;
}

.search-results-item {
	font-size: 16px;
	cursor: pointer;
	color: #707070;
	display: block;
	padding: 0px 20px;
	line-height: 32px;
}

.search-results-item .fa-spinner,
.search-results-item .fa-check {
	position: absolute;
	right: 20px;
	top: 8px;
}

.search-results-item.is-adding {
	pointer-events: none;
	padding-right: 50px;
}

.search-results-item:hover {
	text-decoration: underline;
}

.search-download {
	height: 30px;
	width: 34px;
	margin-top: 2px;
	margin-left: 10px;
	flex-shrink: 0;
	background-color: #666;
	mask-image: url(~@/assets/icon.pdf.svg);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.search-download span {
	display: none;
}

.search-download:hover {
	background-color: #777;
}

</style>
