<template>

<transition>
<div class="location" :class="{'is-active': isViewing}" v-click-outside="onClickOutside">

	<div class="location-content" v-if="isViewing">

		<router-link :to="linkClose" class="location-close"></router-link>

		<div class="location-head">

			<div class="location-head-logo" :style="{backgroundImage: 'url(' + $store.getters['base'] + location.logo + ')'}"></div>
			<div class="location-head-text">

				<h3>{{ location.title }}</h3>
				<h5>{{ locationServices }}</h5>
				
			</div>

		</div>

		<div class="location-map" v-if="location.map" v-html="location.map" />
		
		<app-content v-for="item in location.texts" :key="item" :id="item" />

	</div>

</div>
</transition>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	props: ['id'],
	
	directives: {
		clickOutside: vClickOutside.directive
    },

	data: function() {

		return {
			timer: false,
			locked: true
		}

	},

	methods: {

		onClickOutside: function(e) {

			if (!e.target.classList.contains('content-location')) {
			
				if (!this.locked) this.$router.push(this.linkClose)

			}

		}

	},

	created: function() {

		if (this.isViewing) document.body.classList.add('locked')

	},

	watch: {

		isViewing: {

			deep: true,
			
			handler: function(n) {

				if (n) {

					this.timer = this.$_.delay(function() {

						this.locked = false

					}.bind(this), 500)

					document.body.classList.add('locked')

				} else {

					this.locked = true

					document.body.classList.remove('locked')
		
				}

			}

		}

	},

	computed: {

		mobileOnly: function() {

			return this.$store.getters['mobileOnly']

		},

		linkClose: function() {

			return {
				name: (this.$route.params.item) ? 'Category' : 'Categories'
			}

		},

		location: function() {

			return (this.isViewing) ? this.$_.findWhere(this.$store.getters['locations'], {
				id: this.isViewing
			}) : false

		},

		isViewing: function() {

			return this.$store.getters['location']

		},

		locationServices: function() {

			var services = []

			if (!this.location) return ''

			this.$_.each(this.location.services, function(value) {

				if (this.$constants.locationServices[value]) services.push(this.$constants.locationServices[value])

			}.bind(this))

			return services.join(', ')

		}

	}

}

</script>

<style scoped>

.location {
	position: fixed;
	top: 20px;
	left: 0px;
	overflow-y: auto;
	z-index: 3;
	width: 100%;
	bottom: 0px;
	padding: 20px 20px 20px 20px;
	background-color: #fff;
	border-radius: 20px;
}

.is-actually-desktop .location {
	top: 180px;
	left: 54px!important;
	right: 54px;
	width: calc(100% - 108px);
}

.is-standalone.is-actually-desktop .location {
	top: 0px;
    z-index: 1000;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    left: auto!important;
    right: -480px;
    max-width: 480px;
	width: 100%;
	transition: right 100ms ease-in-out;
    border-radius: 0px;
}

.is-mobile:not(.is-actually-desktop) .location {
	left: 150%;
}

.is-desktop .location {
	position: fixed;
	left: -400px;
	top: 0px;
	width: 400px;
	border-radius: 0px;
	bottom: 0px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	transition: left 100ms ease-in-out;
}

.is-standalone .v-enter-active,
.is-standalone .v-leave-active {
	right: 0px;
}

.is-standalone .v-enter-from,
.is-standalone .v-leave-to {
	right: -480px;
}

.location.is-active {
	left: 0px;
}

.is-mobile:not(.is-actually-desktop) .location.is-active {
	left: 0%!important;
}

.is-standalone .location.is-active {
	left: auto;
	right: 0px!important;
}

.is-mobileOnly .location {
	width: 375px;
	max-height: 647px;
	height: calc(100% - 64px);
	top: 50%;
	left: 50%;
	transform: translate(-50%, calc(-50% + 10px));
	bottom: auto;
	border-radius: 0px 0px 12px 12px;
}

.is-mobileOnly:not(.is-actually-desktop) .location.is-active {
	left: 50%!important;
}

.is-tablet .location {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 0px;
	top: 0px;
	padding: 50px;
}

.is-tablet .location-content {
	background-color: #fff;
	padding: 20px;
	width: 400px;
	left: 50%;
	margin-left: -200px;
}

.location-close {
	width: 20px;
	height: 20px;
	position: absolute;
	font-size: 14px;
	text-align: center;
	line-height: 16px;
	right: -5px;
    top: -5px;
	z-index: 2;
	border-radius: 50%;
	background-image: url(~@/assets/close.png);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.location-map {
	width: 100%;
	height: 260px;
	border-radius: 12px;
	overflow: hidden;
}

.location-map >>> iframe {
	width: 100%!important;
	height: 260px!important;
}

.location-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 20px;
}

.location-head-logo {
	width: 64px;
	height: 64px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 20px;
	flex-shrink: 0;
}

.location-head-text {
	flex-grow: 1;
}

.location-head-text h3 {
	color: #707070;
	font-size: 24px;
	flex-grow: 1;
	margin-bottom: 5px;
}

.location-head-text h5 {
	color: #707070;
	font-size: 16px;
}

</style>
