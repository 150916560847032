export default {

	locationServices: {
		1: 'Give / Donate ',
		2: 'Recycle',
		3: 'Sell',
		4: 'Trade In'
	},

	type: {
		category: 1,
		group: 2,
		item: 3,
		location: 4
	},

	text: {
		typeSlug: {
			1: 'title',
			2: 'tick',
			3: 'cross',
			4: 'check',
			5: 'star',
			6: 'location',
			7: 'pin',
			8: 'link',
			9: 'group',
			10: 'image',
			11: 'text',
			12: 'embed',
			13: 'phone',
			14: 'checklist',
			15: 'connectedgroups'
		},
		type: {
			title: 1,
			tick: 2,
			cross: 3,
			check: 4,
			star: 5,
			location: 6,
			pin: 7,
			link: 8,
			group: 9,
			image: 10,
			text: 11,
			embed: 12,
			phone: 13,
			checklist: 14,
			connectedgroups: 15
		}
	},

}