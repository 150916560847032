<template>

<div class="categories" :style="{minHeight: minHeight.toString() + 'px'}">

	<com-category ref="category" v-for="category in categories" :key="category.id" v-on:loaded="calculateHeight()" :category="category" :expanded="category.id === active" />
	
	<com-location />

</div>

</template>

<script>

import comLocation from './categories/Location'
import comCategory from './categories/Category'

export default {

	components: {
		'com-location': comLocation,
		'com-category': comCategory
	},

	data: function() {

		return {
			minHeight: 0
		}

	},
	
	computed: {

		categories: function() {

			return this.$_.where(this.$store.getters['categories'], {
				visible: 1
			})

		},

		active: function() {

			return this.$store.getters['category']

		}

	},

	watch: {

		active: function() {

			this.$_.delay(function() {
				
				this.calculateHeight()
				
			}.bind(this), 300)

		}

	},

	created: function() {

		this.$nextTick(function() {

			this.calculateHeight()
				
		}.bind(this))

	},

	methods: {

		calculateHeight: function() {

			var height = 0

			this.$_.each(this.$refs.category, function(el) {

				height += el.$el.offsetHeight

			})

			this.minHeight = height

		}

	}

}

</script>

<style scoped>

.categories {
	min-height: calc(100vh - 64px);
	display: flex;
	flex-direction: column;
}

</style>
